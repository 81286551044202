<template>
  <div class="sidebar">
    <!-- 左侧二级菜单栏的组件封装 -->
    <el-menu
      class="sidebar-el-menu"
      :default-active="toIndex()"
      @select="handleSelect"
    >
      <template v-for="item in items">
        <el-menu-item :index="item.index" :key="item.index">
          <!-- 需要图标的在 item 对象中加上属性 icon -->
          <!-- <i :class="item.icon"></i> -->
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>


<script>
export default {
  props: ['items'],
  data () {
    return {

    }
  },
  methods: {
    // 根据路径绑定到对应的二级菜单，防止页面刷新重新跳回第一个
    toIndex () {
      return this.$route.path.split('/')[2];
    },
    // 切换菜单栏
    handleSelect (key) {
      let father = this.$route.path.split('/')[1]
      this.$router.push('/' + father + '/' + key)
    },
  }
}
</script>

<style scoped lang='scss'>
/* 左侧菜单栏定位和位置大小设定 */
/* 左侧二级菜单项的样式 */
.sidebar {
  .sidebar-el-menu {
    border-right: none;
  }
  .el-menu-item {
    font-size: 16px !important;
    color: #666666 !important;
    text-align: center;
    background: #fbfbfb;
    margin-bottom: 2px;
  }

  /* 左侧二级菜单选中时的样式 */
  .el-menu-item.is-active {
    color: white !important;
    background: #e50113 !important;
    font-weight: bold;
  }
  .el-menu-item,
  .el-submenu__title {
    height: 48px !important;
    line-height: 48px !important;
  }
  .el-menu-item:hover {
    background: #ff2132;
    color: rgba(255, 255, 255, 0.9) !important;
  }
}
</style>