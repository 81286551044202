<template>
  <div class="sljr-render">
    <Breadcrumb></Breadcrumb>
    <!-- 一级菜单下面所拥有的二级菜单 -->
    <el-container>
      <el-aside width="228px">
        <SideMenu :items="items"></SideMenu>
      </el-aside>

      <!-- 以及二级菜单所对应的页面 -->
      <el-main class="conten-box">
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import SideMenu from '@/components/SideMenu/SideMenu';
export default {
  components: {
    SideMenu
  },
  data () {
    return {
      items: [
        {
          index: 'annualReport',
          title: '年检年报'
        }, {
          index: 'auditReport',
          title: '审计报告'
        }, {
          title: '计划总结',
          index: 'planSummary'
        }, {
          title: '机构制度',
          index: 'mechanismSystem'
        }, {
          title: '捐款信息公示',
          index: 'donationInfo'
        }, {
          title: '其它信息',
          index: 'otherInfo'
        }
      ],
    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
</style>